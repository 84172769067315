import React, { useState } from "react";

import Layout from "../components/Layout/Layout";
import RegisterForm from "../components/RegisterForm/RegisterForm";
import RegisterItem from "../components/RegisterForm/RegisterItem";
import createRecruiter from '../services/createRecruiter'

import recruiters from "../assets/recruiters.png";

function RegisterRecruiter() {

	const [username, setEmail] = useState();
	const [password, setPassword] = useState();
  	const [lastname, setLastname] = useState();
	const [firstname, setFirstname] = useState();
	const [company, setCompany] = useState();


  const handleSubmit = async (e) => {
		e.preventDefault();
		await createRecruiter(
      username,
      password,
      lastname,
      firstname,
	  company
    );
	};


	return (
		<>
			<Layout>
				<RegisterForm
					subtitle='Vous êtes candidat ? Inscrivez vous'
					redirect='/acces-candidat'
					img={recruiters}
					imgID='people--recruiter'
					changeLastname = {(e) => setLastname(e.target.value)}
              		changeFirstname = {(e) => setFirstname(e.target.value)}
              		changeEmail = {(e) => setEmail(e.target.value)}
              		changePassword = {(e) => setPassword(e.target.value)}
              		submit = {handleSubmit}
				>
					<RegisterItem 
                        placeholder='Nom de votre entreprise *'
						type='text'
						name='company'
						id='input--company'
						onChange={(e) => setCompany(e.target.value)}
                    />
				</RegisterForm>
			</Layout>
		</>
	);
}

export default RegisterRecruiter;

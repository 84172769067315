import React, { useState, useEffect }  from "react";

import "./Panels.css";

function LanguagePanel() {
	// API ENDPOINT STORED IN .ENV FILE

	const [languages, setLanguages] = useState(null);

    useEffect(() => {
	    const { REACT_APP_API } = process.env;
        // GET request using fetch inside useEffect React hook
        fetch(REACT_APP_API + '/get_languages')
            .then(response => response.json())
            .then(data => setLanguages(data.languages.map(language => 
                <li>{language}</li>)
            ));
            
            // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);
    


    
	return (
		<section className='panel'>
			<ul>{languages}</ul>
		</section>
	);
}

export default LanguagePanel;

import React, { useState } from "react";

import getUserData from "../../../services/getUserData";
import useToken from "../../../services/useToken";
import updatePersonalData from "../../../services/Profile/UpdatePersonalData";



function PersonalData() {
	let data = getUserData();
	const token = useToken();

	const [firstname, setFirstname] = useState(data["lastname"]);
	const [lastname, setLastname] = useState(data["firstname"]);
	const [birthday, setBirthday] = useState(data["birthday"]);
	const [location, setLocation] = useState(data["country"]);
	const [email, setEmail] = useState(data["email"]);
	const [mobile, setMobile] = useState(data["mobile"]);
	const [company, setCompany] = useState(data["company"]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		await updatePersonalData(
			token.token,
			firstname,
			lastname,
			birthday,
			location,
			email,
			mobile,
			company
		);
	};

	return (
		<>
			<form className="panel--form">
				<div className="panel--form_item">
					<label htmlFor='lastName'>Nom * : </label>
					<input
						type='text'
						name='lastName'
						defaultValue={data["lastname"]}
						onChange={(e) => setLastname(e.target.value)}
						required
					/>
				</div>

				<div className="panel--form_item">
					<label htmlFor='firstName'>Prénom * : </label>
					<input
						type='text'
						name='firstName'
						defaultValue={data["firstname"]}
						onChange={(e) => setFirstname(e.target.value)}
						required
					/>
				</div>

				<div className="panel--form_item">
					<label htmlFor='birthday'>Date de naissance : </label>
					<input
						type='date'
						name='birthday'
						defaultValue={data["birthday"]}
						onChange={(e) => setBirthday(e.target.value)}
					/>
				</div>

				<div className="panel--form_item">
					<label htmlFor='location'>Lieu de résidence : </label>
					<input
						type='text'
						name='location'
						defaultValue={data["country"]}
						onChange={(e) => setLocation(e.target.value)}
					/>
				</div>

				<div className="panel--form_item">
					<label htmlFor='email'>Email * : </label>
					<input
						type='email'
						name='email'
						defaultValue={data["email"]}
						onChange={(e) => setEmail(e.target.value)}
						required
					/>
				</div>

				<div className="panel--form_item">
					<label htmlFor='phone'>Téléphone : </label>
					<input
						type='tel'
						name='phone'
						defaultValue={data["mobile"]}
						onChange={(e) => setMobile(e.target.value)}
					/>
				</div>

				<div className="panel--form_item">
					<label htmlFor='company'>Entreprise : </label>
					<input
						type='text'
						name='company'
						defaultValue={data["company"]}
						onChange={(e) => setCompany(e.target.value)}
					/>
				</div>
			</form>
			<button onClick={handleSubmit}>Enregistrer les changements</button>
		</>
	);
}

export default PersonalData;

import React from 'react'
import LanguagePanel from './LanguagePanel'

import MainPanel from './MainPanel'

import './Panels.css'
import './Profile.css'

function ProfilePanels() {
  return (
    <>
    <h2 className='profile--title'>
        <span>Mon </span>
        Profil
    </h2>
    <MainPanel/>
    <LanguagePanel/>
    </>
  )
}

export default ProfilePanels
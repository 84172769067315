const { REACT_APP_API } = process.env

export default async function updatePersonalData(
	token,
    firstname,
    lastname,
    birthday,
    location,
    email,
    mobile,
    company
    ) {
	return fetch(REACT_APP_API + "/update_personal_data", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},

		body: JSON.stringify({
			token: token,
			firstname: firstname,
            lastname : lastname,
            birthday: birthday,
            country: location,
            email: email,
            mobile: mobile,
            company: company
		}),
	})

	.then((response) => response.json())
	.then(function(data) {
		if(data.status !== 500) {
			return (
				alert("Mis à jour avec succès"),
				localStorage.setItem('data', JSON.stringify(data)),
				window.location.reload()
				)
		} else {
			return alert('Une erreur est survenue.')
		}})
		.catch((e) => {
			if (e.status !== 200) {
                // TODO: RENDER ERROR ON FRONTEND
				alert("Erreur")
			}

			return Promise.reject(e.json());
		});
}

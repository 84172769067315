import React, {useState} from "react";

import useToken from "../../../services/useToken";
import UpdateProfilePicture from "../../../services/Profile/UpdateProfilePicture";

export default function ProfilePicture() {

  const [image, setImage] = useState({ preview: '', data: '' })
  const status = useState('');
  const token = useToken();

  const handleSubmit = async (e) => {
    e.preventDefault()
    let formData = new FormData()
    formData.append('token', token.token)
	  formData.append('file', image.data)
	  formData.append('preview', image.preview)
	  console.log(image.data)
    await UpdateProfilePicture(formData)
    
  }

  const handleFileChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0]
    }
    setImage(img)
	console.log("Preview here :" + img.preview)
  }



	return (
	
	<>
      {image.preview && <img src={image.preview} alt='Profil' width='100' height='100' />}
      <hr></hr>
      <form onSubmit={handleSubmit} id="upload--pp">
        <input type='file' name='file' accept="image/*" onChange={handleFileChange}></input>
        <button type='submit'>Submit</button>
      </form>
      {status && <h4>{status}</h4>}
	  </>
)}

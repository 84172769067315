import React from "react";

import Login from "../pages/Login";
import Layout from "../components/Layout/Layout";
import ProfilePanels from "../components/Panels/ProfilePanels";


import useToken from "../services/useToken";

function Profile() {
	const { token } = useToken();

	if (!token) {
		return <Login />;
	} else {
		return (
			<Layout>
				<ProfilePanels />
			</Layout>
		);
	}
}

export default Profile;

import React from 'react'

import './Button.css'

function Button(props) {
  return (
    <button
        className='btn'
        onClick={props.onClick}
        style={{
            border: props.border,
            margin: props.margin
        }}
    >
        {props.children}
    </button>
  )
}

export default Button
const { REACT_APP_API } = process.env

export default async function createCandidate(
	username, 
	password, 
	lastname, 
	firstname,
    company) {
	return fetch(REACT_APP_API + "/create", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},

		body: JSON.stringify({
			username: username,
			password: password,
			lastname: lastname,
			firstname: firstname,
            company: company,
			role: 'recruiter'
		}),
	})
		.then((response) => {
            // Return response only if credentials are valid
			if (response.ok) {
				alert("Votre compte a été créé avec succès !")
				return response.json();
			}

			return Promise.reject(response);
		})
		.catch((e) => {
            // Error 401 = bad credentials 
			if (e.status === 401) {
                // TODO: RENDER ERROR ON FRONTEND
				alert("Erreur")
			}

			return Promise.reject(e.json());
		});
}

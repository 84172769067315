import React, { useState } from 'react'

import Layout from '../components/Layout/Layout'
import RegisterForm from '../components/RegisterForm/RegisterForm'
import createCandidate from '../services/createCandidate'

import people2 from "../assets/people-register.png";


function RegisterCandidate() {
  const [username, setEmail] = useState();
	const [password, setPassword] = useState();
  const [lastname, setLastname] = useState();
	const [firstname, setFirstname] = useState();

  const handleSubmit = async (e) => {
		e.preventDefault();
		await createCandidate(
      username,
      password,
      lastname,
      firstname
    );
	};

  return (
    <>
        <Layout>
            <RegisterForm 
              subtitle="Vous êtes recruteur ? Inscrivez vous"
              redirect='/acces-recruteur'
              img={people2}
              imgID='people--candidate'
              changeLastname = {(e) => setLastname(e.target.value)}
              changeFirstname = {(e) => setFirstname(e.target.value)}
              changeEmail = {(e) => setEmail(e.target.value)}
              changePassword = {(e) => setPassword(e.target.value)}
              submit = {handleSubmit}
            />  
        </Layout>
    </>
  )
}

export default RegisterCandidate
import React from "react";

import Layout from "../components/Layout/Layout";
import Home from "./Home";
import LoginForm from "../components/LoginForm/LoginForm";
import useToken from "../services/useToken";


function Login() {
	const { token, setToken } = useToken();

	if (!token) {
		return (
			// Render LoginForm if token doesn't exists
			<Layout>
				<LoginForm setToken={setToken} />
			</Layout>
		);
	} else {
		return (
			// Render Home page if token exists
			<Home />
		);
	}
}

export default Login;

import React from 'react'

import './Tooltip.css'
import Item from './TooltipItem'

function Tooltip(props) {
    
  return (
    <div className='tooltip'>
        <h5>{props.title}</h5>
        <ul>
            <Item 
                text=' - 8 caractères minimum'
            />
            <Item 
                text=' - Au moins un chiffre'
            />
            <Item 
                text=' - Au moins une majuscule/minuscule'
            />
            
        </ul>

    </div>
  )
}

export default Tooltip

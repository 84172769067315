import React from "react";

import "./RegisterForm.css";

// ONLY FOR INPUTS NOT REQUIRED

function RegisterItem(props) {
	return (
		<input
			className='form--input'
			type={props.type}
			name={props.name}
			id={props.id}
			placeholder={props.placeholder}
			onChange={props.onChange}
			required
		/>
	);
}

export default RegisterItem;

import React from "react";
import { Link } from "react-router-dom";

import Tooltip from '../Tooltip/Tootltip'
import "./RegisterForm.css";
import logo from "../../assets/footer-logo.svg";

function RegisterForm(props) {

	return (
		<section id='register'>
			<div id='register--form' className='register--half'>
				<h3 id='form--title'>Inscription</h3>
				<h4 id='form--subtitle'>
					{props.subtitle} <Link to={props.redirect}>ici</Link>
				</h4>

				<form onSubmit={props.submit}>
					<div className='form--half'>
						<input
							className='form--input half--input'
							type='text'
							name='name'
							id='form--name'
							placeholder='Nom *'
							required
							onChange={props.changeLastname}
						/>
						<input
							className='form--input half--input'
							type='text'
							name='first-name'
							id='form--first-name'
							placeholder='Prénom *'
							required
							onChange={props.changeFirstname}
						/>
					</div>
					<input
						className='form--input'
						type='email'
						name='email'
						id='form--email'
						placeholder='Email *'
						required
						onChange={props.changeEmail}
					/>
					<span className="password--field">
					<Tooltip 
						title='Votre mot de passe doit contenir :'
					/>
					<input
						className='form--input'
						type='password'
						name='password'
						id='form--password'
						placeholder='Mot de passe *'
						required
						onChange={props.changePassword}
					/>
					</span>
					<input
						className='form--input'
						type='password'
						name='password-confirmation'
						id='form--password-confirmation'
						placeholder='Confirmation du mot de passe *'
						required
						onChange={props.passwordConfirmation}

					/>
					{props.children}
					<div className='register--chbx form--input'>
						<input type='checkbox' name='remember-me' id='form--remember' />
						<label htmlFor='remember-me' id='register--cgu'>
							{" "}
							Accepter les{" "}
							<Link to='/cgu'>conditions générales d'utilisation</Link>
						</label>
					</div>
					<span id='confidentiality'>
						Voir notre{" "}
						<Link to='/confidentialite' >
							politique de confidentialité
						</Link>
					</span>
					<input
						className='form--input'
						id='form--btn'
						type='submit'
						value="S'inscrire"
					/>
				</form>
			</div>

			<div id='register--illustration' className='register--half'>
				<img
					src={logo}
					alt='Logo Saizon blanc avec la mention La prochaine sans faute'
					id='register--logo'
				/>
				<img
					src={props.img}
					alt='Illustration de candidats aux postes de saisonniers'
					className='register--people'
                    id={props.imgID}
				/>
			</div>
		</section>
	);
}

export default RegisterForm;

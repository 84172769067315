export default function getUserData() {
    let rawData = window.localStorage.getItem("data");
    let data = JSON.parse(rawData)
    return data
}

// DATA API
// ID : data["id"]);
// PRENOM : data["firstname"])
// NOM : data["lastname"])
// EMAIL : data["email"])
// ROLE : data["role"])
// ENTREPRISE : data["company"])

import React from "react";
import { useState, useRef, useEffect } from "react";
import useToken from "../../services/useToken";

import "./Header.css";

import mainLogo from "../../assets/main-logo.svg";

import HeaderItems from "./HeaderItems";

function Header() {
	const [isNavExpanded, setIsNavExpanded] = useState(false);
	const token = useToken();

	// Function made to close menu when clicking outside the component
	const ref = useRef();
	useEffect(() => {
		const checkIfClickedOutside = (e) => {
			// If the menu is open and the clicked target is not within the menu,
			// then close the menu
			if (isNavExpanded && ref.current && !ref.current.contains(e.target)) {
				setIsNavExpanded(false);
			}
		};
		document.addEventListener("mousedown", checkIfClickedOutside);
		return () => {
			// Cleanup the event listener
			document.removeEventListener("mousedown", checkIfClickedOutside);
		};
	}, [isNavExpanded]);

	if (!token) {
		return (
			<header>
				<a href='/'>
					<img
						id='header--logo'
						src={mainLogo}
						alt='Logo de Saizon, couleur verte'
					/>
				</a>

				<button
					className='hamburger'
					onClick={() => {
						setIsNavExpanded(!isNavExpanded);
					}}
				>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						className='h-6 w-6'
						fill='none'
						viewBox='0 0 24 24'
						stroke='currentColor'
						strokeWidth='2'
					>
						<path
							strokeLinecap='round'
							strokeLinejoin='round'
							d='M4 6h16M4 12h16M4 18h16'
						/>
					</svg>
				</button>

				<nav
					id='header--nav'
					className={isNavExpanded ? "nav--displayed" : "nav--hidden"}
					ref={ref}
				>
					<ul className='menu'>
						<HeaderItems
							class='nav--item'
							route='/recherche'
							itemName="Rechercher une offre d'emploi"
						/>
						<HeaderItems
							class='nav--item'
							route='/communication'
							itemName='Communiquer sur Saizon'
						/>
						<HeaderItems
							class='nav--item'
							route='/partenaires'
							itemName='Nos partenaires'
						/>
						<HeaderItems
							class='nav--item nav--button'
							route='/connexion'
							itemName='Connexion'
						/>
					</ul>
				</nav>

				<nav id='subnav'>
					<ul>
						<HeaderItems
							class='subnav--item'
							route='/acces-candidat'
							itemName='Inscription candidat'
						/>

						<HeaderItems
							class='subnav--item'
							route='/acces-recruteur'
							itemName='Inscription recruteur'
						/>
					</ul>
				</nav>
			</header>
		);
	} else {
		return (
			<header>
				<a href='/'>
					<img
						id='header--logo'
						src={mainLogo}
						alt='Logo de Saizon, couleur verte'
					/>
				</a>

				<button
					className='hamburger'
					onClick={() => {
						setIsNavExpanded(!isNavExpanded);
					}}
				>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						className='h-6 w-6'
						fill='none'
						viewBox='0 0 24 24'
						stroke='currentColor'
						strokeWidth='2'
					>
						<path
							strokeLinecap='round'
							strokeLinejoin='round'
							d='M4 6h16M4 12h16M4 18h16'
						/>
					</svg>
				</button>

				<nav
					id='header--nav'
					className={isNavExpanded ? "nav--displayed" : "nav--hidden"}
					ref={ref}
				>
					<ul className='menu'>
						<HeaderItems
							class='nav--item'
							route='/recherche'
							itemName="Rechercher une offre d'emploi"
						/>
						<HeaderItems
							class='nav--item'
							route='/communication'
							itemName='Communiquer sur Saizon'
						/>
						<HeaderItems
							class='nav--item'
							route='/partenaires'
							itemName='Nos partenaires'
						/>
						<HeaderItems
							class='nav--item nav--button'
							route='/profil'
							itemName='Mon profil'
						/>
					</ul>
				</nav>

				<nav id='subnav'>
					<ul>
						<HeaderItems 
							class='subnav--item' 
							route='/' 
							itemName='Item 1' 
						/>

						<HeaderItems 
							class='subnav--item' 
							route='/' 
							itemName='Item 2' 
						/>

						<HeaderItems 
							class='subnav--item' 
							route='/' 
							itemName='Item 3' 
						/>

					</ul>
				</nav>
			</header>
		);
	}
}

export default Header;

const { REACT_APP_API } = process.env;

export default async function updatePersonalData(formData) {
	return fetch(REACT_APP_API + "/upload_file", {
		method: "POST",
		body: formData,
	})
	.then((response) => response.json())
	.then(function(data) {
		console.log(data)
		if(data.status !== 500) {
			return (
				alert("Mis à jour avec succès"),
				localStorage.setItem('profilePic', JSON.stringify(data)),
				window.location.reload()
				)
		} else {
			return alert('Une erreur est survenue.')
		}})
		.catch((e) => {
			if (e.status !== 200) {
                // TODO: RENDER ERROR ON FRONTEND
				alert("Erreur")
			}

			return Promise.reject(e.json());
		});
}
// API ENDPOINT STORED IN .ENV FILE
const { REACT_APP_API } = process.env;

// FUNCTION CALLED ON FORM SUBMIT
export default async function createCandidate(
	username,
	password,
	lastname,
	firstname
) {
	return fetch(REACT_APP_API + "/create", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},

		body: JSON.stringify({
			username: username,
			password: password,
			lastname: lastname,
			firstname: firstname,
			role: "candidate",
		}),
	})
		.then((response) => {
			// Return response only if credentials are valid
			if (response.ok) {
				return alert("Votre compte a été créé avec succès");
			}
			return Promise.reject(response);
		})
		.catch((e) => {
			// Error 401 = bad credentials
			if (e.status !== 200) {
				alert("Erreur pendant la création de votre compte " + e.message);
			}
			return Promise.reject(e.json());
		});
}

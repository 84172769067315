import React from "react";
import { useNavigate } from "react-router-dom";

import Layout from "../components/Layout/Layout";
import Button from "../components/Button/Button";

import useToken from "../services/useToken";
import { disconnect } from "../services/disconnect";

function Home() {
	const {token} = useToken();
	const navigate = useNavigate();
    

	// TEMP // WILL DELETE
	const tempStyle = {
		margin: "10rem auto",
		textAlign: "center",
		fontSize: "2rem",
	};

	if (!token) {
		return (
			<Layout>
				{/* TEMP // WILL DELETE */}
				<h1 style={tempStyle}>
					Pour profiter pleinement de Saizon, vous devriez vous connecter
				</h1>
				<Button
					onClick={() => navigate("/connexion")}
					border='none'
					margin='1rem auto 5rem auto'
					children='Se connecter'
				/>
			</Layout>
		);
	} else {
		return (
			<Layout>
				<h1>Vous êtes bien connecté bravo</h1>
				<button onClick={disconnect}>Supprimer token</button>
			</Layout>
		);
	}
}

export default Home;

import React from 'react'
import { Link } from 'react-router-dom'

function HeaderItems(props) {
  return (
    <li className={props.class}>
        <Link to={props.route}>{props.itemName}</Link>
    </li>
  )
}

export default HeaderItems
import React, { useState } from "react";
import PropTypes from "prop-types";

import "./LoginForm.css";
import logo from "../../assets/footer-logo.svg";
import people from "../../assets/people-login.png";

import loginUser from "../../services/loginUser";

function LoginForm({ setToken }) {
	const [username, setEmail] = useState();
	const [password, setPassword] = useState();

	const handleSubmit = async (e) => {
		e.preventDefault();
		const token = await loginUser(username, password);
		setToken(token);
		window.location.reload();
	};

	return (
		<section id='login'>
			<div id='login--form' className='login--half'>
				<h3 id='form--title'>Déjà inscrit ?</h3>
				<h4 id='form--subtitle'>Connectez-vous à votre compte </h4>
				<form onSubmit={handleSubmit}>
					<input
						className='form--input'
						type='email'
						name='email'
						id='form--email'
						placeholder='Email *'
						required
						onChange={(e) => setEmail(e.target.value)}
					/>
					<input
						className='form--input'
						type='password'
						name='password'
						id='form--password'
						placeholder='Mot de passe *'
						required
						onChange={(e) => setPassword(e.target.value)}
					/>
					<div className='login--chbx form--input'>
						<label htmlFor='remember-me'>Se souvenir de moi :</label>
						<input type='checkbox' name='remember-me' id='form--remember' />
					</div>
					<input
						className='form--input'
						id='form--btn'
						type='submit'
						value='Se connecter'
						onClick={console.log()}
					/>
				</form>

				<div className='access--links'>
					<p>Pas encore inscrit ?</p>
					<span>
						Créer un compte
						<a href='/acces-candidat'>candidat</a>
						ou
						<a href='/acces-recruteur'>recruteur</a>
					</span>
				</div>
			</div>

			<div id='login--illustration' className='login--half'>
				<img
					src={logo}
					alt='Logo Saizon blanc avec la mention La prochaine sans faute'
					id='login--logo'
				/>
				<img
					src={people}
					alt='Illustration de candidats aux postes de saisonniers'
					id='login--people'
				/>
			</div>
		</section>
	);
}

LoginForm.propTypes = {
	setToken: PropTypes.func.isRequired,
};

export default LoginForm;

import React from 'react'

function TooltipItem(props) {
  return (
    <li>
        {props.text}
    </li>
  )
}

export default TooltipItem

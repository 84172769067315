import React from "react";
import Popup from "reactjs-popup";


// PROPS LIST : 
// title = modal title
// form = Form Component tied to panel
// onClick = function sending data
// illustration = content of the button trigerring the form
// 
// 

function Modal(props) {
	return (
		<Popup
			trigger={<button className='panel--edit'>
            <img
                src={props.illustration}
                alt='Modifier mes informations'
                className='edit--icon'
            />
        </button>}
			modal
			nested
		>
			{(close) => (
				<div className='modal'>
					
					<div className='header'>
                        {props.title}
                    </div>
					<div className='content'>
						{props.form}
					</div>
					<div className='actions'>
						<button
							className='button'
							onClick={() => {
								close();
							}}
						>
							Annuler
						</button>
					</div>
				</div>
			)}
		</Popup>
	);
}

export default Modal;

import React from "react";

import getUserData from "../../services/getUserData";
import useToken from "../../services/useToken";

import Login from "../../pages/Login";
import Modal from "./Modal";
import PersonalData from "./Forms/PersonalData";
import ProfilePicture from "./Forms/ProfilePicture";

import pp from "../../assets/pp.png";
import cog from "../../assets/icons/cog-solid.svg";

import "./Panels.css";

function MainPanel() {
	const { token } = useToken();

	if (token) {
		let data = getUserData();
		

		if (data["picture"] !== undefined && data["picture"] !== null) {
			return (
				<>
					<section className='panel' id='main--panel'>
						<div className='panel--header'>
							<h5>Données personnelles</h5>
							<Modal
								title='Données personnelles'
								form={<PersonalData />}
								// onClick={updatePersonalData}
								// TO REMOVE IF NO BUG
								illustration={cog}
							/>
						</div>

						<div className='panel--content'>
							<div className='panel--left_content panel--col'>
								<Modal
									title='Photo de profil'
									form={<ProfilePicture />}
									illustration={data["picture"]}
								/>
							</div>

							<div className='wrapper--profile'>
								<div className='panel--center_content panel--col'>
									<li>
										<h5>Nom</h5>
										<span>{data["lastname"]}</span>
									</li>
									<li>
										<h5>Date de naissance</h5>
										<span>{data["birthday"]}</span>
									</li>
									<li>
										
										<h5>Téléphone</h5>
										<span>{data["mobile"]}</span>
									</li>
								</div>

								<div className='panel--right_content panel--col'>
									<li>
										<h5>Prénom</h5>
										<span>{data["firstname"]}</span>
									</li>
									<li>
										<h5>Lieu de résidence</h5>
										<span>{data["country"]}</span>
									</li>
									<li>
										<h5>Email</h5>
										<span>{data["email"]}</span>
									</li>
								</div>
							</div>
						</div>
					</section>
				</>
			);
		} else {
			
			return (
				<>
					<section className='panel' id='main--panel'>
						<div className='panel--header'>
							<h5>Données personnelles</h5>
							<Modal
								title='Données personnelles'
								form={<PersonalData />}
								// onClick={updatePersonalData}
								// TO REMOVE IF NO BUG
								illustration={cog}
							/>
						</div>

						<div className='panel--content'>
							<div className='panel--left_content panel--col'>
								<Modal
									title='Photo de profil'
									form={<ProfilePicture />}
									illustration={pp}
								/>
							</div>

							<div className='wrapper--profile'>
								<div className='panel--center_content panel--col'>
									<li>
										<h5>Nom</h5>
										<span>{data["lastname"]}</span>
									</li>
									<li>
										<h5>Date de naissance</h5>
										<span>{data["birthday"]}</span>
									</li>
									<li>
										
										<h5>Téléphone</h5>
										<span>{data["mobile"]}</span>
									</li>
								</div>

								<div className='panel--right_content panel--col'>
									<li>
										<h5>Prénom</h5>
										<span>{data["firstname"]}</span>
									</li>
									<li>
										<h5>Lieu de résidence</h5>
										<span>{data["country"]}</span>
									</li>
									<li>
										<h5>Email</h5>
										<span>{data["email"]}</span>
									</li>
								</div>
							</div>
						</div>
					</section>
				</>
			);
		}
	} else {
		return <Login />;
	}
}

export default MainPanel;

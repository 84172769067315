import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import './index.css';

import Home from './pages/Home'
import Login from './pages/Login'
import RegisterCandidate from './pages/RegisterCandidate'
import RegisterRecruiter from './pages/RegisterRecruiter'
import Profile from './pages/Profile'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
    
      <Route path="/" element={<Home />} /> 
      <Route path="/connexion" element={<Login />} />
      <Route path="/acces-candidat" element={<RegisterCandidate />} />
      <Route path="/acces-recruteur" element={<RegisterRecruiter />} />
      <Route path="/profil" element={<Profile />} />

    </Routes> 
  </BrowserRouter>
);

